<template>
    <Dialog id="createFeedModal" :header="dialogLabels.header" v-model:visible="display" :modal="true" class='create-feed-modal' :draggable='false' @show='onShown'>
        <div class='channel-name-input-container'>
            <div :class="{'p-float-label': true, 'input-error': v$.name.$error }">
                <InputText id='nameInput' type="text" v-model='v$.name.$model' @input="()=>resetError('name')" :disabled="feed && feed.groupFeedId == 0"/>
                <label for='nameInput' class='input-label'>{{isModule ? "Module name" : "Feed name"}}</label>
            </div>
            <template v-for="error of v$.name.$errors" :key="error.$uid">
                <span class='error-message'>{{error.$message}}</span>
            </template>
        </div>
        <div class="feed-field-radiobutton" v-if="!isModule && (!feed || feed && feed.groupFeedId > 0)">
            <div>
                <RadioButton inputId="publicChoice" :value="false" v-model="privateFeed" :disabled="editMode"/>
                <label for="publicChoice">Public</label>
            </div>
            <div>
                <RadioButton inputId="privateChoice" :value="true" v-model="privateFeed" :disabled="editMode"/>
                <label for="privateChoice">Private</label>
            </div>
            
        </div>
        <div class="feed-type-description">
            <span>{{feedTypeDescription}}</span>
        </div>
        <div class='channel-desc-textarea-container' v-if="!feed || feed.groupFeedId > 0">
            <span :class="{'p-float-label': true, 'input-error': v$.description.$error }">
                <Textarea id='descriptionTextarea' rows="5" cols="30" v-model='v$.description.$model'/>
                <label for='descriptionTextarea' class='input-label'>{{isModule ? "Module description" : "Feed description"}}</label>
                <span class='channel-desc-counter' :style="{color: v$.description.$error ? '#E63E3E': null}">{{ (description ? description.length:0)}} / {{channelDescLimit}} </span>
            </span>
        </div>
        <div class="row" v-if="!this.isModule">
            <div class="column">
                <span>Show Full Post Content:</span>
            </div>
            <div class="column">
                <InputSwitch v-model="showFullContent" class="group-actions-switch"  />
            </div>
        </div>
        <div class="row" v-if="!this.isModule">
            <div class="column">
                <span>Allow Posts:</span>
            </div>
            <div class="column">
                <InputSwitch v-model="allowMemberPosts" class="group-actions-switch"  />
            </div>
        </div>
        <div class="row" v-if="!this.isModule">
            <div class="column">
                <span>Allow Comments:</span>
            </div>
            <div class="column">
                <InputSwitch v-model="allowMemberComments" class="group-actions-switch"  />
            </div>
        </div>
        <div class="row" v-if="!this.isModule">
            <div class="column">
                <span>Allow Likes:</span>
            </div>
            <div class="column">
                <InputSwitch v-model="allowMemberLikes" class="group-actions-switch"  />
            </div>
        </div>
        <template #footer>
            <Button class='create-channel-button' :label="dialogLabels.footerButton" :disabled='disableSubmitButton' :loading='loading' @click='onFooterButtonClicked()'/>
        </template>
    </Dialog>

</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import useVuelidate from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators';

import GroupService from '../../../service/GroupService';

import EventBus from '../../../event-bus';

const dialogLabelsOptions = {
    createFeed: {
        header: "Create Feed",
        footerButton: "Create"
    },
    editFeed: {
        header: "Edit Feed",
        footerButton: "Save"
    },
    createModule: {
        header: "Create Module",
        footerButton: "Create"
    },
    editModule: {
        header: "Edit Module",
        footerButton: "Save"
    }
}

export default {
    name: 'CreateEditFeedModal',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            dialogLabels: dialogLabelsOptions.createFeed,
            feed: null,
            course: null,
            isModule: false,

            display: false,
            editMode: false,
            selectedGroup: null,
            name: null,
            description: null,
            loading: false,
            channelDescLimit: 200,
            privateFeed: null,
            allowMemberPosts: true,
            allowMemberComments: true,
            allowMemberLikes: true,
            inviteOnly: false,
            showFullContent: false,
        }
    },
    components: {
        Dialog, InputText, Textarea, RadioButton,InputSwitch
    },

    computed: {
        disableSubmitButton() {
            const isNameAndDescEmpty = !this.name && !this.description 
            return this.v$.$errors.length > 0 || isNameAndDescEmpty ? 'disabled' : null;
        },

        feedTypeDescription() {
            if( this.privateFeed != null){
                if( this.privateFeed ){
                    return 'Posts made to this feed are only visible by group members.  This setting cannot be changed once saved.';
                }
                else {
                    return 'Posts made to this feed are visible by group members as well as non-members.  Only members can make posts or comment. This setting cannot be changed once saved.';
                }
            }
            else {
                return '';
            }
        }
    },

    methods: {
        resetError(field) {
            this.v$[field].$reset();
        },

        onShown() {
            //const dialogElement = document.getElementsByClassName('create-feed-modal')[0];
            //dialogElement.parentElement.style.position = 'absolute';
        },

        open(selectedGroup, feed, course) {
            this.course = course;
            this.selectedGroup = selectedGroup;
            this.v$.$reset();
            this.display = true;
            this.name = '';
            this.description = '';
            this.privateFeed = null;
            this.showFullContent = false;

            this.allowMemberPosts = true;
            this.allowMemberComments = true;
            this.allowMemberLikes = true;

            if(feed){
                this.isModule = feed?.courseId >= 0;
                this.dialogLabels = this.isModule ? dialogLabelsOptions.editModule : dialogLabelsOptions.editFeed;
                this.editMode = true;
                this.feed = feed;
                this.initFeedInfo();
                this.v$.$touch();
            } else {
                this.isModule = course?.courseId >= 0;
                this.dialogLabels  = this.isModule ? dialogLabelsOptions.createModule : dialogLabelsOptions.createFeed;
                this.editMode = false;
                this.feed = null;
            }
        },

        initFeedInfo() {
            this.name = this.feed.name;
            this.description = this.feed.description;

            this.allowMemberPosts = this.feed.groupFeedId > 0 ? this.feed.allowMemberPosts : this.selectedGroup.allowMemberPosts;
            this.allowMemberComments = this.feed.groupFeedId > 0 ? this.feed.allowMemberComments : this.selectedGroup.allowMemberComments;
            this.allowMemberLikes = this.feed.groupFeedId > 0 ? this.feed.allowMemberLikes : this.selectedGroup.allowMemberLikes;
            
            this.privateFeed = this.feed.privateFeed;
            this.inviteOnly = this.feed.inviteOnly;
            this.showFullContent = this.feed.groupFeedId > 0 ? this.feed.showFullContent : this.selectedGroup.showFullContent;
            console.log("initFeedInfo", this.feed, this.name);
        },

        onFooterButtonClicked() {

            if(this.feed && this.feed.groupFeedId == 0) {
                this.updateGroupLatestFeed();
                return;
            }

            const onErrorResponse = (resp) => {
                if( resp.data.message == 'Invalid Name') {
                    this.$toast.add({ severity: 'error', summary: "Invalid feed name.", life: 3500, group: 'center' });
                } else if( resp.data.message == 'Quota Full') {
                    this.$toast.add({ severity: 'info', summary: "Feeds are limited to four per group. (Coming soon: For a nominal monthly fee you can add more.)", life: 4500, group: 'center' });
                
                } else {
                    console.error(resp.data.message);
                    this.$toast.add({ severity: 'error', summary: "An unexpected error occurred.  Please try again or click on 'Support' under your profile pic.", life: 3500, group: 'center' });
                }
            }

            this.v$.name.$touch();

            if (this.v$.name.$errors.length == 0 && this.selectedGroup) {
                this.loading = true;
                if(!this.editMode){
                    GroupService.createGroupFeed(this.selectedGroup.groupId, this.name, this.description, this.isModule ? true : this.privateFeed,
                        this.allowMemberPosts, this.allowMemberComments, this.allowMemberLikes, this.inviteOnly, 
                        this.showFullContent, this.course?.courseId).then((resp) => {

                        this.loading = false;

                        const summaryString = `Created ${this.isModule ? "Module" : "Feed"}: ${this.name}`;

                        if (resp.data.status === 'success') {
                            this.display = false;
                            this.$toast.add({ severity: 'success', summary: `${summaryString} successful!`, life: 2500, group: 'center' });
                           
                            if(this.isModule){
                                EventBus.emit('refresh-courses-list');
                            } else {
                                EventBus.emit('refresh-my-feed-list');
                            }
                        } else {
                            onErrorResponse(resp);
                        }
                    });
                } else {
                    GroupService.updateGroupFeed(this.selectedGroup.groupId, this.feed.groupFeedId, this.name, this.description, this.isModule ? true : this.privateFeed,
                        this.allowMemberPosts, this.allowMemberComments, this.allowMemberLikes, this.inviteOnly, this.showFullContent, this.course?.courseId).then((resp) => {

                        this.loading = false;

                        const summaryString = `Updated ${this.isModule ? "Module" : "Feed"}: ${this.name}`;

                        if (resp.data.status === 'success') {
                            this.display = false;
                            this.$toast.add({ severity: 'success', summary: `${summaryString} successful!`, life: 2500, group: 'center' });
                            EventBus.emit("updated-group-feed-actions", resp.data.groupFeed);
                            if(this.isModule){
                                EventBus.emit('refresh-courses-list');
                            } else {
                                EventBus.emit('refresh-my-feed-list');
                            }
                        } else {
                            onErrorResponse(resp);
                        }
                    });
                }
            }

        },

        updateGroupLatestFeed() {
            GroupService.updateGroupSettings(this.selectedGroup.groupId, this.selectedGroup.defaultGroupPortfolioId, this.showFullContent, 
                this.allowMemberPosts, this.allowMemberComments, this.allowMemberLikes)
                .then(resp => {
                    if (resp.data.status == "success" && resp.data.group) {
                        this.display = false;
                        this.$store.commit('SET_SELECTED_GROUP', resp.data.group);

                        this.$toast.add({ severity: 'success', summary: `Updated Latest Feed!`, life: 2500, group: 'center' });
                        // console.log("updateGroupSettings", resp.data.group, this.selectedGroup);
                    } else {
                        this.$toast.add({ severity: 'error', summary: `Unable to update group feed settings due to an unexpected error.`, life: 2500, });
                    }
                });
        },
    },

    validations() {
        return {
            name: {
                maxLength: maxLength(50)
            },

            description: {
                maxLength: maxLength(this.channelDescLimit)
            }
        }
    }
}
</script>

<style s>

.create-feed-modal {
    /* position: static; */
    font-family: "Trebuchet MS", "Verdana";
    max-width: 400px;
    width: 400px;
    border-radius: 16px;
}

.create-feed-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.create-feed-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.create-feed-modal .p-dialog-content {
    height: 100%;
    /* overflow-y: hidden; */
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}

.create-feed-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>

.feed-field-radiobutton {
    margin-bottom: 10px;
    padding: 0px 80px;
    display:flex;
    justify-content: space-around;
}

.feed-field-radiobutton .p-radiobutton {
    margin-right: 4px;
}

/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.channel-name-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.channel-name-input-container .p-inputtext {
    font-size: 16px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.channel-name-input-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}
.channel-name-input-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}
.channel-name-input-container > label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.channel-desc-textarea-container {
    margin-bottom: 8px;
}
.channel-desc-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.channel-desc-textarea-container .p-inputtextarea::-webkit-scrollbar {
	/* width */
	width: 8px;
}
.channel-desc-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
	background-color: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 9px solid #BFBFBF;  /* creates padding around scroll thumb */
}
.channel-desc-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}
.channel-desc-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}
.channel-desc-textarea-container > label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}
.channel-desc-textarea-container .channel-desc-counter {
    font-size: 12px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}
.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}
.input-error .input-label {
    color: #E63E3E;
}
.error-message:nth-child(2) {
    padding-top: 10px;
}
.error-message {
    color: #E63E3E;
    padding-left: 10px;
}

.create-channel-button {
    padding: 8px 32px;
    background: #33CC99;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}
.create-channel-button:hover {
    background: #00C083;
    border-color: #00C083;
}
.create-channel-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}


.group-actions-switch-container {
    display: grid;
    width: 200px;
    margin: 0px 16px 16px;
}
.group-actions-switch {
    margin-left: auto;
    display: flex;
}

.row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
}

.column {
    flex-grow: 1;
}
</style>

<style scoped>
.feed-type-description {
    margin-top: 0;
    margin-bottom: 25px;
}
</style>